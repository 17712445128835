var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-navbar",
    {
      staticStyle: { "box-shadow": "0 3px 3px 0 lightgrey" },
      attrs: { type: "is-info" },
    },
    [
      _c(
        "template",
        { slot: "brand" },
        [
          _c(
            "b-navbar-item",
            { staticStyle: { "font-size": "24px" }, attrs: { href: "/" } },
            [
              _c("i", {
                staticClass: "fa fa-arrow-left",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v("  " + _vm._s(_vm.toolbarBrand) + "\n      "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "start" },
        [
          _c(
            "b-tag",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$store.state.appStatus.online,
                  expression: "!$store.state.appStatus.online",
                },
              ],
              staticStyle: { "margin-top": "1.2em" },
              attrs: { type: "is-warning" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("toolbar.Offline mode")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "end" },
        [
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newButtonVisible,
                  expression: "newButtonVisible",
                },
              ],
              staticClass: "bar-title",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("reset-wheel")
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-file fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.New")) + "\n      "),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.optionsButtonVisible,
                  expression: "optionsButtonVisible",
                },
              ],
              staticClass: "bar-title",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("open-customize-dialog")
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-palette fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("common.Customize")) + "\n      "),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.fullscreenButtonVisible,
                  expression: "fullscreenButtonVisible",
                },
              ],
              staticClass: "bar-title",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$store.commit("enterFullScreen")
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-expand fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.Fullscreen")) + "\n      "),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.exitFullscreenButtonVisible,
                  expression: "exitFullscreenButtonVisible",
                },
              ],
              staticClass: "bar-title",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$store.commit("exitFullScreen")
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-compress fa-fw" }),
              _vm._v(
                " " + _vm._s(_vm.$t("toolbar.Exit fullscreen")) + "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.unlinkSheetButtonVisible,
                  expression: "unlinkSheetButtonVisible",
                },
              ],
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$store.commit("unlinkSheet")
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-unlink fa-fw" }),
              _vm._v(
                " " +
                  _vm._s(_vm.$t("toolbar.Unlink Google Spreadsheet")) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newButtonVisible,
                  expression: "newButtonVisible",
                },
              ],
              staticClass: "bar-title",
              staticStyle: { color: "yellow", "font-weight": "bold" },
              attrs: { href: "/hotwheels.html" },
              on: {
                click: function ($event) {
                  return _vm.gotoWheels("HotWheelsPageGoto")
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-link fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.HotWheels")) + "\n      "),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newButtonVisible,
                  expression: "newButtonVisible",
                },
              ],
              staticClass: "bar-title",
              staticStyle: { color: "orangered", "font-weight": "bold" },
              attrs: { href: "/top10wheels.html" },
              on: {
                click: function ($event) {
                  return _vm.gotoWheels("Top10WheelsPageGoto")
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-link fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.Top10Wheels")) + "\n      "),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              staticStyle: { color: "yellow", "font-weight": "bold" },
              attrs: {
                href: "https://fidget-spinner.net?fm=a",
                target: "_blank",
              },
            },
            [
              _c("i", { staticClass: "fa fa-link fa-fw" }),
              _vm._v(" Fidget Spinner\n      "),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newButtonVisible,
                  expression: "newButtonVisible",
                },
              ],
              staticClass: "bar-title",
              attrs: { href: "/sitemap.html" },
            },
            [
              _c("i", { staticClass: "fa fa-link fa-fw" }),
              _vm._v(" " + _vm._s(_vm.$t("toolbar.Sitemap")) + "\n      "),
            ]
          ),
          _vm._v(" "),
          _vm.$mq === "mobile"
            ? _c(
                "b-navbar-dropdown",
                { attrs: { label: _vm.$t("toolbar.Language") } },
                _vm._l(_vm.locales, function (locale) {
                  return _c(
                    "b-navbar-item",
                    {
                      key: locale.name,
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("set-locale", locale.name)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(locale.humanName) +
                          "\n        "
                      ),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq !== "mobile"
            ? _c(
                "b-navbar-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.languageVisible,
                      expression: "languageVisible",
                    },
                  ],
                  attrs: { tag: "div", href: "#" },
                },
                [
                  _c(
                    "b-select",
                    {
                      model: {
                        value: _vm.locale,
                        callback: function ($$v) {
                          _vm.locale = $$v
                        },
                        expression: "locale",
                      },
                    },
                    _vm._l(_vm.locales, function (locale) {
                      return _c(
                        "option",
                        { key: locale.name, domProps: { value: locale.name } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(locale.humanName) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }